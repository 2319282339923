.gnav-header-formatter__gnav .gnav-header-formatter__sections--pc .header-gnav-section__label,
.gnav-header-formatter__gnav .gnav-header-formatter__sections--pc .header-gnav-section__link,
.gnav-header-formatter__gnav .gnav-header-formatter__hamburger--pc .text-link--style-2,
.gnav-header-formatter__sections-panel .header-gnav-section__label,
.gnav-header-formatter__sections-panel .header-gnav-section__link,
.gnav-header-formatter__sections .header-gnav-links__title,
.gnav-header-formatter__sections .header-gnav-section__panel-link--header,
.sitewide-footer-formatter .sitewide-footer-formatter__links .footer-links__title {
  font-family: HelveticaNeueLTStd45Light, Arial, Sans-Serif;
  font-weight: 700;
}
.gnav-header-formatter__sections .header-gnav-section__panel .header-gnav-links .header-gnav-link {
  font-family: HelveticaNeueLTStd45Light, Arial, Sans-Serif;
  display: initial;
}
.header-gnav-account-link .header-gnav-account-link__wrapper a,
.sitewide-footer-formatter__links .footer-links__link .text-link--style-2,
.sitewide-footer-formatter .sitewide-footer-formatter__copyright .custom-text p {
  font-family: HelveticaNeueLTStd45Light, Arial, Sans-Serif;
}
